import React from "react";
import styled from "styled-components";
import Image from "next/image";

import NakedButton from "@components/common/buttons/NakedButton";
import SVGLogo from "@components/navigation/SVGLogo";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDown } from "@fortawesome/pro-light-svg-icons";
import { UnmountClosed } from "react-collapse";

import { useRecoilValue } from "recoil";
import { userState } from "@atoms/user";
import ScrollLock, { TouchScrollable } from "react-scrolllock";

const DockContainer = styled.div`
  width: 100%;
  height: 100%;
  background: #fff;
  max-height: 100%;
  position: fixed;
  top: 0px;
  transition: all 200ms ease-in-out;
  z-index: 999999;
  -webkit-overflow-scrolling: touch;
  box-sizing: border-box;
  overflow-x: hidden;
  overflow-y: hidden;
  transform: ${(props) => (props.visible ? "translateX(0%)" : "translateX(-100%)")};
`;

const MobileLinkContainer = styled.div`
  padding: 75px 20px 140px;
  transform: translate3d(0, 0, 0);
  height: 100vh;
  overflow-y: scroll;

  box-sizing: border-box;
  & button {
    display: block;
    width: 100%;
    text-align: left;
  }
  & hr {
    border-color: ${(props) => props.theme.colors.darkPrimary};
  }
`;

const CloseModalButton = styled.div`
  position: absolute;
  top: 10px;
  right: 15px;
  z-index: 99;
  & button {
    border: 2px solid rgb(226, 226, 226);
    background: white;
    font-size: 5rem;
    color: ${(props) => props.theme.colors.darkPrimary};
    border-radius: 100px;
    height: 45px;
    width: 45px;
    display: flex;
    justify-content: center;
    align-items: center;
    & span {
      margin-top: -3.5px;
      font-family: ${(props) => props.theme.fonts.paragraph};
      font-weight: 400;
    }
  }
`;

const NavTopBar = styled.div`
  background: ${(props) => props.theme.gradients.lightBlue};
  background-size: 400% 400%;
  animation: gradient 2s ease infinite;
  position: absolute;
  z-index: 99;
  width: 100%;
  @keyframes gradient {
    0% {
      background-position: 0% 50%;
    }
    50% {
      background-position: 100% 50%;
    }
    100% {
      background-position: 0% 50%;
    }
  }
`;

const NavTopBarInner = styled.div`
  padding: 12px 20px 9px;
`;

const Logo = styled.a`
  display: block;
  & svg {
    width: 80px;
    height: 45px;

    & path,
    polygon {
      transition: ${(props) => props.theme.transitions.standard};
      fill: ${(props) => props.theme.colors.primary};
    }
    :hover {
      & path,
      polygon {
        transition: ${(props) => props.theme.transitions.standard};
        fill: ${(props) => props.theme.colors.lightPrimary};
      }
    }
  }
  & p {
    font-size: 0px;
  }
`;

const BottomLinkContainer = styled.div`
  position: fixed;
  bottom: 0px;
  left: 0px;
  height: 60px;
  background: white;
  box-shadow: -2px -7px 9px rgb(189 189 189 / 18%);
  width: 100%;
  display: flex;
  justify-content: space-around;
  align-items: center;
  & button {
    flex-basis: 50%;
    text-align: center;
  }
  & p {
    font-size: 2.2rem;
  }
`;

const MobileNavLink = styled.p`
  font-size: 2rem;
  text-transform: uppercase;
  text-decoration: none;
  color: ${(props) => props.theme.colors.darkPrimary};
  font-family: ${(props) => props.theme.fonts.paragraph};
  font-weight: 600;
  position: relative;
  display: block;
  margin: 0px;
  line-height: 58px;
  border-bottom: ${(props) => !props.noBorder && "1px solid #bce0dd"};
  & span {
    padding-right: 5px;
    display: none;
    font-size: 2.3rem;
  }
  & svg {
    position: absolute;
    right: 10px;
    top: 18px;
    transform: ${(props) => (props.productMenuOpen ? "rotate(180deg)" : "rotate(0deg)")};
    font-size: 2.1rem;
    transition: ${(props) => props.theme.transitions.standard};
  }
`;

const ProductLinkContainer = styled.div`
  padding: 10px 0px 10px 10px;
  border-bottom: ${(props) => !props.noBorder && "1px solid #bce0dd"};
  & p {
    font-size: 1.9rem;
    line-height: 30px;
    border-bottom: 0px;
  }
  & button {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding-bottom: 5px;
    & img {
      padding-right: 16px !important;
    }
    & span {
      font-size: 1.55rem;
      max-width: 215px;
      color: black !important;
      display: block;
      opacity: 0.7;
    }
  }
  & button:first {
    padding-bottom: 0px;
  }
  & button :first-child {
    padding: 0px;
    margin-bottom: -5px;
  }
`;

function MobileNavigation({
  toggleMobileMenuOpen,
  mobileMenuOpen,
  pushMobileLink,
  productMenuOpen,
  toggleProductMenuOpen,
}) {
  const user = useRecoilValue(userState);
  return (
    <>
      <ScrollLock isActive={mobileMenuOpen} />
      <TouchScrollable>
        <DockContainer visible={mobileMenuOpen} id="mobileMenu">
          <NavTopBar>
            <NavTopBarInner>
              <NakedButton onClick={() => pushMobileLink("/")}>
                <Logo>
                  <SVGLogo />
                  <p>Cleancult home page</p>
                </Logo>
              </NakedButton>
              <CloseModalButton>
                <NakedButton onClick={toggleMobileMenuOpen}>
                  <span>×</span>
                </NakedButton>
              </CloseModalButton>
            </NavTopBarInner>
          </NavTopBar>
          <MobileLinkContainer>
            <NakedButton onClick={() => pushMobileLink("/products/shop/all")}>
              <MobileNavLink>Shop</MobileNavLink>
            </NakedButton>
            {/* <NakedButton onClick={() => toggleProductMenuOpen()}>
              <MobileNavLink productMenuOpen={productMenuOpen}>
                Shop
                <FontAwesomeIcon icon={faChevronDown} width={17} />
              </MobileNavLink>
            </NakedButton>
            <UnmountClosed isOpened={productMenuOpen}>
              <ProductLinkContainer>
                <NakedButton
                  onClick={() => pushMobileLink("/products/shop/bundles")}
                >
                  <Image
                    alt={"Shop Bundles"}
                    src={
                      "https://images.ctfassets.net/1s5kub6totmj/4WhSioLlS9432OJGsR0n1Q/0d5eb3da6e859130ea6674f7f677d543/Midnight_Blue-Hand_Soap_Bundle.png"
                    }
                    width={76}
                    height={76}
                    blurDataURL={`https://images.ctfassets.net/1s5kub6totmj/4WhSioLlS9432OJGsR0n1Q/0d5eb3da6e859130ea6674f7f677d543/Midnight_Blue-Hand_Soap_Bundle.png?w=50&q=1`}
                    placeholder="blur"
                  />
                  <div>
                    <MobileNavLink>Shop Bundles</MobileNavLink>
                    <span>Pay less to get more with our bundles.</span>
                  </div>
                </NakedButton>
                <NakedButton
                  onClick={() => pushMobileLink("/products/shop/refills")}
                >
                  <Image
                    alt={"Shop Refills"}
                    src={
                      "https://images.ctfassets.net/1s5kub6totmj/gihtPQl2rZHStpfgNxq3g/7d614395f6628395cb17b79a0ce1d6ef/FHS-Wild_Lavender-A.png"
                    }
                    width={76}
                    height={76}
                    blurDataURL={`https://images.ctfassets.net/1s5kub6totmj/gihtPQl2rZHStpfgNxq3g/7d614395f6628395cb17b79a0ce1d6ef/FHS-Wild_Lavender-A.png?w=50&q=1`}
                    placeholder="blur"
                  />
                  <div>
                    <MobileNavLink>Shop Refills</MobileNavLink>
                    <span>Refills in recyclable milk cartons.</span>
                  </div>
                </NakedButton>
                <NakedButton
                  onClick={() => pushMobileLink("/products/shop/glass-bottles")}
                >
                  <Image
                    alt={"Shop Bottles"}
                    src={
                      "https://images.ctfassets.net/z3ixs6i8tjtq/73I33rSMgW7QVlg3zlC7GO/5ce8a5df617ca7c24dbc734e24f31f87/Artboard.png"
                    }
                    width={76}
                    height={76}
                    blurDataURL={`https://images.ctfassets.net/z3ixs6i8tjtq/73I33rSMgW7QVlg3zlC7GO/5ce8a5df617ca7c24dbc734e24f31f87/Artboard.png?w=50&q=1`}
                    placeholder="blur"
                  />
                  <div>
                    <MobileNavLink noBorder>Shop Bottles</MobileNavLink>
                    <span>Beautifully, practicality designed bottles.</span>
                  </div>
                </NakedButton>
              </ProductLinkContainer>
            </UnmountClosed> */}
            <NakedButton onClick={() => pushMobileLink("/refill-ritual")}>
              <MobileNavLink>Refill Ritual</MobileNavLink>
            </NakedButton>
            <NakedButton onClick={() => pushMobileLink("/sustainability")}>
              <MobileNavLink>Sustainability</MobileNavLink>
            </NakedButton>
            <NakedButton onClick={() => (window.location = "https://strongthumbs.com")}>
              <MobileNavLink>Get Free Samples</MobileNavLink>
            </NakedButton>
            <NakedButton onClick={() => pushMobileLink("/store-locator")}>
              <MobileNavLink>Stores</MobileNavLink>
            </NakedButton>
          </MobileLinkContainer>
          <BottomLinkContainer>
            <NakedButton onClick={() => pushMobileLink(user ? "/profile/your-next-box" : "/login")}>
              <MobileNavLink>My Account</MobileNavLink>
            </NakedButton>
            <NakedButton onClick={() => pushMobileLink("/help")}>
              <MobileNavLink>FAQs</MobileNavLink>
            </NakedButton>
          </BottomLinkContainer>
        </DockContainer>
      </TouchScrollable>
    </>
  );
}

export default MobileNavigation;
